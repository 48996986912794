import React, { useState, useEffect, useRef } from "react";
import { BiChevronDown } from "react-icons/bi";
import { BsChevronDown, BsX } from "react-icons/bs";

const LtSelect = ({ options = [], isMulti = false, isSearch = false, onChange, validate = [], value, placeholder = "Select..." }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const dropdownRef = useRef(null);

  const toggleOpen = () => setIsOpen(!isOpen);

  const handleOptionClick = option => {
    if (isMulti) {
      const newSelectedOptions = selectedOptions.includes(option) ? selectedOptions.filter(item => item !== option) : [...selectedOptions, option];
      setSelectedOptions(newSelectedOptions);
      onChange(newSelectedOptions);
    } else {
      setSelectedOptions([option]);
      setIsOpen(false);
      onChange(option);
    }
  };

  const handleSearchChange = e => setSearchValue(e.target.value);

  const handleRemoveOption = option => {
    const newSelectedOptions = selectedOptions.filter(item => item !== option);
    setSelectedOptions(newSelectedOptions);
    onChange(newSelectedOptions);
  };

  const filteredOptions = isSearch ? options.filter(option => option.label.toLowerCase().includes(searchValue.toLowerCase())) : options;

  useEffect(() => {
    const handleClickOutside = event => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // useEffect(() => {
  //   if (value) {
  //     if (isMulti && Array.isArray(value)) {
  //       setSelectedOptions(options.filter(option => value.includes(option.value)));
  //     } else {
  //       setSelectedOptions(options.filter(option => option.value === value));
  //     }
  //   } else {
  //     setSelectedOptions([]);
  //   }
  // }, [value, options, isMulti]);

  useEffect(() => {
    if (value) {
      if (isMulti && Array.isArray(value)) {
        const newSelectedOptions = options.filter(option => value.includes(option.value));
        if (JSON.stringify(newSelectedOptions) !== JSON.stringify(selectedOptions)) {
          setSelectedOptions(newSelectedOptions);
        }
      } else {
        const newSelectedOption = options.find(option => option.value === value);
        if (newSelectedOption && JSON.stringify([newSelectedOption]) !== JSON.stringify(selectedOptions)) {
          setSelectedOptions([newSelectedOption]);
        }
      }
    } else if (selectedOptions.length !== 0) {
      setSelectedOptions([]);
    }
  }, [value, options, isMulti, selectedOptions]);

  const styles = {
    backgroundSize: 18,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "calc(100% - 3px) center",
    backgroundColor: "#fff",
    border: `1px solid ${
      !validate && value && value.length > 0 ? "rgb(222, 226, 230)" : validate && validate.length > 0 ? "red" : "rgb(222, 226, 230)"
    }`,
    backgroundImage:
      !validate && value && value.length > 0
        ? "url(/assets/images/check_icon.png)"
        : validate && validate.length > 0
          ? "url(/assets/images/cross_icon.png)"
          : "none",
  };

  return (
    <div className="custom-select position-relative" ref={dropdownRef}>
      <div className="select-input form-control" style={styles} onClick={toggleOpen}>
        {isMulti === true
          ? selectedOptions.length > 0 && selectedOptions
            ? selectedOptions.map(option => (
                <span key={option.value} className={`selected-option ${isMulti && "bg-primary text-primary m-1 rounded-md px-1 py-1 text-xs"}`}>
                  {option.label}
                  {isMulti && <BsX onClick={() => handleRemoveOption(option)} />}
                </span>
              ))
            : placeholder
          : options.find(v => v.value === value)?.label || selectedOptions[0]?.label || placeholder}
      </div>
      <span className="position-absolute inline-block p-2" style={{ top: -1, right: -1 }}>
        <BsChevronDown />
      </span>
      {isOpen && (
        <div className="select-dropdown z-1 card position-absolute w-100 left-0 p-2">
          {isSearch && (
            <input className="form-control w-100 h-[30px]" type="text" value={searchValue} onChange={handleSearchChange} placeholder="Search..." />
          )}

          <ul className="options-list m-0 list-none p-0" style={{ maxHeight: 200, overflow: "auto" }}>
            {filteredOptions.length > 0 ? (
              filteredOptions.map(option => (
                <li
                  key={option.value}
                  className={`option cursor-pointer p-2 hover:bg-slate-200 ${
                    selectedOptions.includes(option) ? "selected disabled pointer-events-none bg-slate-200 text-slate-400" : ""
                  } ${option.disabled ? " disabled pointer-events-none bg-slate-200 text-slate-400" : ""}`}
                  onClick={() => !selectedOptions.includes(option) && handleOptionClick(option)}
                  style={{ pointerEvents: selectedOptions.includes(option) ? "none" : "auto" }}>
                  {option.label}
                </li>
              ))
            ) : (
              <li className="option text-muted p-2 text-center">No options found</li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default LtSelect;

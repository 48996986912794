import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Label from "../components/Label";
import CustomInput from "../components/CustomInput";
import LtSelect from "../components/LtSelect";
import Button from "../components/Button";
import { Col, Form, OverlayTrigger, Row } from "react-bootstrap";
import { FiNavigation  } from "react-icons/fi";
import { themeColor2 } from "../components/utils";

const validationSchema = Yup.object({
  name: Yup.string().required("Required"),
  email: Yup.string().email("Invalid email address").required("Required"),
  phone: Yup.string().required("Required"),
  clubName: Yup.string().required("Required"),
  clubStatus: Yup.string().required("Required"),
  clubType: Yup.string().required("Required"),
  numberOfCourts: Yup.number().required("Required").positive().integer(),
  typeOfCourts: Yup.string().required("Required"),
  sport: Yup.string().required("Required"),
  academy: Yup.string().required("Required"),
  clubCountry: Yup.string().required("Required"),
  city: Yup.string().required("Required"),
  privacyPolicy: Yup.boolean().oneOf([true], "You must accept the privacy policy"),
});

export default function RequestForm() {
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      clubName: "",
      clubStatus: "",
      clubType: "",
      numberOfCourts: "",
      typeOfCourts: "",
      sport: "",
      academy: "",
      clubCountry: "",
      city: "",
      marketingCommunications: false,
      privacyPolicy: false,
    },
    validationSchema,
    onSubmit: values => {
      console.log(JSON.stringify(values, null, 2));
    },
  });
  return (
    <>
      <OverlayTrigger
        trigger="click"
        placement="top"
        overlay={
          <div style={{maxHeight:'calc(100vh - 100px)', boxShadow:'0px 0px 10px 0px rgba(0,0,0,0.3)', zIndex:'10000', position:'fixed'}} className=" max-w-[500px] overflow-auto rounded bg-white p-[20px]">
            <h5 className="mt-3 mb-4">If you prefer, you can request more info to our experts</h5>
            <Form onSubmit={formik.handleSubmit}>
              <Row>
                <Col className="col-lg-6 col-12 mb-3">
                  <Label aria-required>Name</Label>
                  <CustomInput
                    validate={formik.touched.name && formik.errors.name}
                    type="text"
                    className="form-control"
                    placeholder="Name"
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.name && formik.errors.name ? <div className="error">{formik.errors.name}</div> : null}
                </Col>

                <Col className="col-lg-6 col-12 mb-3">
                  <Label aria-required>Email</Label>
                  <CustomInput
                    validate={formik.touched.email && formik.errors.email}
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.email && formik.errors.email ? <div className="error">{formik.errors.email}</div> : null}
                </Col>

                <Col className="col-lg-6 col-12 mb-3">
                  <Label aria-required>Phone</Label>
                  <CustomInput
                    validate={formik.touched.phone && formik.errors.phone}
                    type="text"
                    className="form-control"
                    placeholder="Phone"
                    name="phone"
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.phone && formik.errors.phone ? <div className="error">{formik.errors.phone}</div> : null}
                </Col>

                <Col className="col-lg-6 col-12 mb-3">
                  <Label aria-required>Club Name</Label>
                  <CustomInput
                    validate={formik.touched.clubName && formik.errors.clubName}
                    type="text"
                    className="form-control"
                    placeholder="Club Name"
                    name="clubName"
                    value={formik.values.clubName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.clubName && formik.errors.clubName ? <div className="error">{formik.errors.clubName}</div> : null}
                </Col>

                <Col className="col-lg-6 col-12 mb-3">
                  <Label aria-required>Club Status</Label>
                  <LtSelect
                    validate={formik.touched.clubStatus && formik.errors.clubStatus}
                    value={formik.values.clubStatus}
                    onChange={value => formik.setFieldValue("clubStatus", value.value)}
                    options={[
                      { label: "Please Select", value: "" },
                      // Add more options here
                    ]}
                  />
                  {formik.touched.clubStatus && formik.errors.clubStatus ? <div className="error">{formik.errors.clubStatus}</div> : null}
                </Col>

                <Col className="col-lg-6 col-12 mb-3">
                  <Label aria-required>Club Type</Label>
                  <LtSelect
                    validate={formik.touched.clubType && formik.errors.clubType}
                    value={formik.values.clubType}
                    onChange={value => formik.setFieldValue("clubType", value.value)}
                    options={[
                      { label: "Please Select", value: "" },
                      // Add more options here
                    ]}
                  />
                  {formik.touched.clubType && formik.errors.clubType ? <div className="error">{formik.errors.clubType}</div> : null}
                </Col>

                <Col className="col-lg-6 col-12 mb-3">
                  <Label aria-required>Number of Courts</Label>
                  <CustomInput
                    validate={formik.touched.numberOfCourts && formik.errors.numberOfCourts}
                    type="number"
                    className="form-control"
                    placeholder="Number of Courts"
                    name="numberOfCourts"
                    value={formik.values.numberOfCourts}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.numberOfCourts && formik.errors.numberOfCourts ? <div className="error">{formik.errors.numberOfCourts}</div> : null}
                </Col>

                <Col className="col-lg-6 col-12 mb-3">
                  <Label aria-required>Type of Courts</Label>
                  <LtSelect
                    validate={formik.touched.typeOfCourts && formik.errors.typeOfCourts}
                    value={formik.values.typeOfCourts}
                    onChange={value => formik.setFieldValue("typeOfCourts", value.value)}
                    options={[
                      { label: "Please Select", value: "" },
                      // Add more options here
                    ]}
                  />
                  {formik.touched.typeOfCourts && formik.errors.typeOfCourts ? <div className="error">{formik.errors.typeOfCourts}</div> : null}
                </Col>

                <Col className="col-lg-6 col-12 mb-3">
                  <Label aria-required>Sport</Label>
                  <LtSelect
                    validate={formik.touched.sport && formik.errors.sport}
                    value={formik.values.sport}
                    onChange={value => formik.setFieldValue("sport", value.value)}
                    options={[
                      { label: "Please Select", value: "" },
                      // Add more options here
                    ]}
                  />
                  {formik.touched.sport && formik.errors.sport ? <div className="error">{formik.errors.sport}</div> : null}
                </Col>

                <Col className="col-lg-6 col-12 mb-3">
                  <Label aria-required>Academy</Label>
                  <LtSelect
                    validate={formik.touched.academy && formik.errors.academy}
                    value={formik.values.academy}
                    onChange={value => formik.setFieldValue("academy", value.value)}
                    options={[
                      { label: "Please Select", value: "" },
                      // Add more options here
                    ]}
                  />
                  {formik.touched.academy && formik.errors.academy ? <div className="error">{formik.errors.academy}</div> : null}
                </Col>

                <Col className="col-lg-6 col-12 mb-3">
                  <Label aria-required>Club Country</Label>
                  <LtSelect
                    validate={formik.touched.clubCountry && formik.errors.clubCountry}
                    value={formik.values.clubCountry}
                    onChange={value => formik.setFieldValue("clubCountry", value.value)}
                    options={[
                      { label: "Please Select", value: "" },
                      // Add more options here
                    ]}
                  />
                  {formik.touched.clubCountry && formik.errors.clubCountry ? <div className="error">{formik.errors.clubCountry}</div> : null}
                </Col>

                <Col className="col-lg-6 col-12 mb-3">
                  <Label aria-required>City</Label>
                  <CustomInput
                    validate={formik.touched.city && formik.errors.city}
                    type="text"
                    className="form-control"
                    placeholder="City"
                    name="city"
                    value={formik.values.city}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.city && formik.errors.city ? <div className="error">{formik.errors.city}</div> : null}
                </Col>

                <Col className="col-lg-12 col-12 mb-3">
                  <Label>
                    <input
                      type="checkbox"
                      name="marketingCommunications"
                      checked={formik.values.marketingCommunications}
                      onChange={formik.handleChange}
                    />{" "}
                    Ewizpro marketing communications: I consent to the processing of my personal data for the purpose of sending marketing
                    communications as indicated in the privacy policy.
                  </Label>
                </Col>

                <Col className="col-lg-12 col-12 mb-3">
                  <Label>
                    <input type="checkbox" name="privacyPolicy" checked={formik.values.privacyPolicy} onChange={formik.handleChange} /> Privacy
                    policy: I confirm that I have read the privacy policy.
                  </Label>
                  {formik.touched.privacyPolicy && formik.errors.privacyPolicy ? <div className="error">{formik.errors.privacyPolicy}</div> : null}
                </Col>
                <Col className="col-lg-12 col-12 mb-3">
                  <Button type="submit" variant="primary">
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        }>
        <span className="position-fixed z-1 border shadow-lg bottom-[30px] right-[30px] flex items-center justify-center h-[50px] w-[50px] cursor-pointer rounded-full bg-white " style={{fontSize:25, color:themeColor2()}}>
          <FiNavigation  />
        </span>
      </OverlayTrigger>
    </>
  );
}

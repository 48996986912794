import axios from "axios";

const PATHNAME = document.querySelector("body").addEventListener("click", function (e) {
  return window.location.pathname;
});

window.addEventListener("click", handleLocationChange);

const DOMAIN = window.location.hostname;

const headers = {
  Accept: "*",
  Accept: "application/json",
  "Content-type": "application/json",
  "X-Client-Domain": DOMAIN,
  "x-url-path": PATHNAME,
};

function handleLocationChange() {
  const path = window.location.pathname;
  headers["x-url-path"] = path;
  // Do whatever you need to do with the path variable
}

const token = () => {
  if (localStorage.getItem("userinfo")) {
    let tokenVal = localStorage.getItem("userinfo").replace(/['"]+/g, "");
    return "Bearer " + tokenVal;
  }
};

function joinurl(baseURL, url) {
  return `${baseURL}/${url}`;
}

const baseUrl = () => {
  if (window.location.hostname.includes("dlta")) {
    return process.env.REACT_APP_BASE_URL_DLTA;
  } else if (window.location.hostname.includes("gstc")) {
    return process.env.REACT_APP_BASE_URL_DLTA;
  } else if (window.location.hostname.includes("futurepro")) {
    return process.env.REACT_APP_BASE_URL_DLTA;
  } else if (window.location.hostname.includes("smashit")) {
    return process.env.REACT_APP_BASE_URL_DLTA;
  } else if (window.location.hostname.includes("hkita")) {
    return process.env.REACT_APP_BASE_URL_DLTA;
  } else if (window.location.hostname.includes("sportama")) {
    return process.env.REACT_APP_BASE_URL_SPORTAMA;
  } else if (window.location.hostname.includes("htf")) {
    return process.env.REACT_APP_BASE_URL_HTF;
  } else if (window.location.hostname.includes("coretennis")) {
    return process.env.REACT_APP_BASE_URL_CORETENNIS;
  } else if (window.location.hostname.includes("tceislingen")) {
    return process.env.REACT_APP_BASE_URL_TCEISLINGEN;
  } else if (window.location.hostname.includes("demo")) {
    return process.env.REACT_APP_BASE_URL_DEMO;
  } else {
    return process.env.REACT_APP_BASE_URL_ACADEMY;
  }
};
class Service {
  constructor() {
    // this.domain = process.env.REACT_APP_BASE_URL;
    this.domain = baseUrl();
  }

  componentDidUpdate(prevProps) {
    headers["x-url-path"] = window.location.pathname;
  }

  request() {
    return { ...headers, Authorization: token() };
  }

  post(url, data) {
    const method = "POST";
    return axios
      .post(joinurl(this.domain, url), data, { headers: this.request() })
      .then(res => {
        const { data = {} } = res;
        return data;
      })
      .catch(error => error);
  }

  get(url, id) {
    if (id) {
      url = `${url}/${id}`;
    }
    return axios
      .get(joinurl(this.domain, url), { headers: this.request() })
      .then(res => {
        const { data = {} } = res;
        return data;
      })
      .catch(error => {
        if (error.response.statusText === "Unauthorized") {
          localStorage.clear();
          localStorage.setItem("tokenExpired", "true");
          window.location.href === "/";
          window.location.reload();
        }
      });
  }

  delete(url, data) {
    return axios
      .delete(joinurl(this.domain, url), { headers: this.request() })
      .then(res => {
        const { data = {} } = res;
        return data;
      })
      .catch(error => error);
  }

  put(url, data) {
    return axios
      .put(joinurl(this.domain, url), data, { headers: this.request() })
      .then(res => {
        const { data = {} } = res;
        return data;
      })
      .catch(error => error);
  }
  getAll() {}
}
export default Service;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Service from "../http";
const services = new Service();

// Define the initial state
const initialState = {
  reports: [],
  status: "idle", // Possible values: 'idle', 'loading', 'succeeded', 'failed'
  error: null,
};

// Create an asynchronous thunk for fetching reports
export const fetchReports = createAsyncThunk("reports/fetchReports", async filters => {
  // Accept filters as parameters
 
    const queryString = Object.keys(filters)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(filters[key]))
      .join("&");
    const response = await services.get(`reports/listview?${queryString}`);
    return response.data;

});

// Create a slice
const reportsSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    // Define reducers if needed
  },
  extraReducers: builder => {
    builder
      .addCase(fetchReports.pending, state => {
        state.status = "loading";
      })
      .addCase(fetchReports.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.reports = action.payload;
      })
      .addCase(fetchReports.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

// Export actions and reducer
export const {} = reportsSlice.actions; // Add any additional actions if needed
export default reportsSlice.reducer;

// Get the first theme color from the local storage or use a default value
export const themeColor1 = () => (localStorage.getItem("theme") ? localStorage.getItem("theme").split(",")[0] : "#F2B000");

// Get the second theme color from the local storage or use a default value
export const themeColor2 = () => (localStorage.getItem("theme") ? localStorage.getItem("theme").split(",")[1] : "#2F308C");
// console.log(localStorage.getItem('theme')&&localStorage.getItem('theme').split(','))
// Capitalize the first letter of each word in the input string
export const capitalizeFirstLetter = string => {
  return string
    .split(/\s+/)
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

// Check if a given hexadecimal color is considered light or dark
export const wc_hex_is_light = color => {
  const hex = color.replace("#", "");
  const c_r = parseInt(hex.substring(0, 0 + 2), 16);
  const c_g = parseInt(hex.substring(2, 2 + 2), 16);
  const c_b = parseInt(hex.substring(4, 4 + 2), 16);
  const brightness = (c_r * 299 + c_g * 587 + c_b * 114) / 1000;

  return brightness > 155;
};

// Get an array of weeks in the given year
export function getWeeksOfYear(year) {
  const weeks = [];
  let date = new Date(year, 0, 1); // Start with January 1st of the given year

  // Find the first Monday of the year
  while (date.getDay() !== 1) {
    date.setDate(date.getDate() + 1);
  }

  const options = { month: "short", day: "2-digit", year: "numeric" };

  while (date.getFullYear() == year) {
    weeks.push({
      label: date.toLocaleDateString("en-US", options),
      value: date.toISOString().split("T")[0], // Get the date part of ISO string (YYYY-MM-DD)
    });

    date.setDate(date.getDate() + 7); // Move to the next week (Monday of the next week)
  }

  return weeks;
}

export function tableSerialNumber(index, currentPage = 1, itemsOnPage, defaultEntryCount = 25) {
  return (currentPage - 1) * (currentPage > 1 ? defaultEntryCount : itemsOnPage) + (index + 1);
}

// Calculate the current date and get the year value three months later
const currentDate = new Date();
currentDate.setMonth(currentDate.getMonth() + 3);
const yearValueThreeMonthsLater = currentDate.getFullYear();

// Create an array of years from the current year to three months later
export function createRangeWithForLoop(start) {
  const range = [];
  for (let i = start; i <= yearValueThreeMonthsLater; i++) {
    range.push({ label: i + "", value: i + "" });
  }
  return range;
}

// Convert a UTC date-time string to local date and time
export function convertToLocaleDateTime(utcDateTimeString) {
  // Create a new Date object using the UTC date string
  const dateObj = new Date(utcDateTimeString);

  // Convert the date to the local timezone
  const localDate = dateObj.toLocaleDateString();
  const localTime = dateObj.toLocaleTimeString();

  // Return the local date and time as an object
  return {
    localDate: localDate,
    localTime: localTime,
  };
}

export function removeDuplicates(inputString) {
  const inputArray = inputString.split(",");

  const uniqueArray = [...new Set(inputArray)];

  const resultArray = uniqueArray.filter(item => item.trim() !== "");

  const resultString = resultArray.join(",");

  return resultString;
}

export function convertDateRange(dateRange) {
  const [startDateStr, endDateStr] = dateRange.split(" - ");

  const startDate = new Date(startDateStr);

  const endDate = new Date(endDateStr);

  const startDay = startDate.getDate();

  const startMonth = new Intl.DateTimeFormat("en", { month: "short" }).format(startDate);

  const endDay = endDate.getDate();

  const endMonth = new Intl.DateTimeFormat("en", { month: "short" }).format(endDate);

  const formattedDateRange = `${startDay} ${startMonth} - ${endDay} ${endMonth}`;

  return formattedDateRange;
}

export function formatTimeWithSeconds(inputTime) {
  // Ensure the input time has a valid format (HH:mm)
  if (/^\d{2}:\d{2}$/.test(inputTime)) {
    const currentTime = new Date();
    const [hours, minutes] = inputTime.split(":");
    currentTime.setHours(hours);
    currentTime.setMinutes(minutes);
    currentTime.setSeconds(0); // Set seconds to 0
    const formattedTime = currentTime.toLocaleTimeString("en-US", {
      hour12: false, // Ensure 24-hour format
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
    return formattedTime;
  } else {
    return "Invalid time format";
  }
}

export function convertToReadableFormat(dateString) {
  // Split the input string into date and time parts
  const [datePart, timePart] = dateString.split(",");

  // Further split the date and time parts
  const [year, month, day] = datePart.split("-");
  const [hours, minutes, seconds] = timePart.split(":");

  // Create and return a new Date object
  return new Date(year, month - 1, day, hours, minutes, seconds);
}

export function formatTime(time) {
  // Getting hours, minutes, and seconds from the provided Date object
  if (time) {
    const hours = time.getHours();
    const minutes = time.getMinutes();
    const seconds = time.getSeconds();

    // Formatting hours, minutes, and seconds to ensure they have two digits
    const formattedHours = hours < 10 ? "0" + hours : hours;
    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
    const formattedSeconds = seconds < 10 ? "0" + seconds : seconds;

    // Constructing the hh:mm:ss format
    const formattedTime = `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;

    return formattedTime;
  }
}

export function shortDate(currentdate) {
  const date = new Date(currentdate);
  // Define an array of month names
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  // Get the day, month, and year from the Date object
  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();

  // Format the date string
  const formattedDate = `${day} ${monthNames[monthIndex]}, ${year}`;

  return formattedDate;
}

export function getDiscountedPrice(discount, price) {
  const discountedAmount = (discount / 100) * price;
  const discountedPrice = price - discountedAmount;
  return discountedPrice;
}

const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

const addLeadingZero = value => {
  return value < 10 ? `0${value}` : value;
};

export const formatDate = dateString => {
  const date = new Date(dateString);

  const day = addLeadingZero(date.getDate());
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  let hours = date.getHours();
  const minutes = addLeadingZero(date.getMinutes());
  const seconds = addLeadingZero(date.getSeconds());

  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12 || 12; // Convert to 12-hour format

  const timeString = `${addLeadingZero(hours)}:${minutes}:${seconds} ${ampm}`;

  return `${day} ${month}, ${year} ${timeString}`;
};

export function amount(number, separator = ",", decimalSeparator = ".") {
  return parseFloat(number)
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, separator)
    .replace(".", decimalSeparator);
}

export function formatPrice(price) {
  if (price == null || isNaN(price)) {
    return "";
  }
  const parts = price.toFixed(2).toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}

export function getAgeGroup(dob, gender) {
  const today = new Date();
  const birthDate = new Date(dob);
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();
  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }

  let ageGroup = "";

  if (age < 6) {
    ageGroup = "U6";
  } else if (age < 8) {
    ageGroup = "U8";
  } else if (age < 10) {
    ageGroup = "U10";
  } else if (age < 12) {
    ageGroup = "U12";
  } else if (age < 14) {
    ageGroup = "U14";
  } else if (age < 16) {
    ageGroup = "U16";
  } else if (age < 18) {
    ageGroup = "U18";
  } else if (age < 30) {
    ageGroup = "18+";
  } else if (age < 35) {
    ageGroup = gender === "male" ? "M30+" : "W30+";
  } else if (age < 40) {
    ageGroup = gender === "male" ? "M35+" : "W35+";
  } else if (age < 45) {
    ageGroup = gender === "male" ? "M40+" : "W40+";
  } else if (age < 50) {
    ageGroup = gender === "male" ? "M45+" : "W45+";
  } else if (age < 55) {
    ageGroup = gender === "male" ? "M50+" : "W50+";
  } else if (age < 60) {
    ageGroup = gender === "male" ? "M55+" : "W55+";
  } else if (age < 65) {
    ageGroup = gender === "male" ? "M60+" : "W60+";
  } else if (age < 70) {
    ageGroup = gender === "male" ? "M65+" : "W65+";
  } else if (age < 75) {
    ageGroup = gender === "male" ? "M70+" : "W70+";
  } else if (age < 80) {
    ageGroup = gender === "male" ? "M75+" : "W75+";
  } else if (age < 85) {
    ageGroup = gender === "male" ? "M80+" : "W80+";
  } else {
    ageGroup = gender === "male" ? "M85+" : "W85+";
  }

  return ageGroup;
}

export const getTimeRange = (start, end, withoutSeconds = false) => {
  const times = [];
  let currentTime = new Date(`1970-01-01T${start}Z`);
  const endTime = new Date(`1970-01-01T${end}Z`);

  while (currentTime <= endTime) {
    const hours = String(currentTime.getUTCHours()).padStart(2, "0");
    const minutes = String(currentTime.getUTCMinutes()).padStart(2, "0");
    const seconds = String(currentTime.getUTCSeconds()).padStart(2, "0");

    if (withoutSeconds) {
      times.push(`${hours}:${minutes}`);
    } else {
      times.push(`${hours}:${minutes}:${seconds}`);
    }

    // Increment time by 30 minutes
    currentTime.setUTCMinutes(currentTime.getUTCMinutes() + 30);
  }

  return times;
};

export const getMinutesDifference = (startTime, endTime) => {
  // Parse the time strings into Date objects
  const [startHours, startMinutes, startSeconds] = startTime.split(":").map(Number);
  const [endHours, endMinutes, endSeconds] = endTime.split(":").map(Number);

  // Create Date objects with the time on the same day
  const startDate = new Date();
  startDate.setHours(startHours, startMinutes, startSeconds, 0);

  const endDate = new Date();
  endDate.setHours(endHours, endMinutes, endSeconds, 0);

  // Calculate the difference in milliseconds
  const differenceInMillis = endDate - startDate;

  // Convert milliseconds to minutes
  const differenceInMinutes = Math.floor(differenceInMillis / (1000 * 60));

  return differenceInMinutes;
};

export function calculateTimeDifferenceInHours(startTime, endTime) {
  // Parse the times
  const startParts = startTime.split(":").map(Number);
  const endParts = endTime.split(":").map(Number);

  // Create Date objects with the same date but different times
  const startDate = new Date(0, 0, 0, startParts[0], startParts[1], startParts[2]);
  const endDate = new Date(0, 0, 0, endParts[0], endParts[1], endParts[2]);

  // Calculate the difference in milliseconds
  let diff = endDate - startDate;

  // If the difference is negative, adjust by adding 24 hours
  if (diff < 0) {
    diff += 24 * 60 * 60 * 1000;
  }

  // Convert milliseconds to hours
  const hours = diff / (1000 * 60 * 60);

  return Math.round(hours);
}

export function addHoursToTime(time, hours = 1) {
  const parsedTime = parseInt(time);
  if (parsedTime >= 10) {
    return `${parsedTime + hours}:00:00`;
  }

  if (parsedTime < 10) {
    return `${parsedTime + hours < 10 ? "0" : ""}${parsedTime + hours}:00:00`;
  }
}

export function timeStringToDate(startDate, timeString) {
  // Create a new Date object based on startDate
  const newDate = new Date(startDate);

  // Split the timeString into hours, minutes, and seconds
  const [hours, minutes, seconds] = timeString.split(":").map(Number);

  // Set the hours, minutes, and seconds on the new Date object
  newDate.setHours(hours);
  newDate.setMinutes(minutes);
  newDate.setSeconds(seconds);
  newDate.setMilliseconds(0); // Optionally set milliseconds to 0

  return newDate;
}

export function formatDateToYYYYMMDD(dateString) {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
  const day = String(date.getDate()).padStart(2, "0");
  // console.log(`${year}-${month}-${day}`);
  return `${year}-${month}-${day}`;
}

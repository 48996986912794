import store from "../redux/store";

// export let currentLanguage;
// export let shortLanguage;

// Access Redux state to set currentLanguage and shortLanguage variables
const languageState = store.getState()?.frontend;
[
  {
      "language_id": 56,
      "language": {
          "id": 56,
          "language_code": "hi",
          "language_name": "Hindi - हिन्दी"
      }
  }
]
const lang = JSON.parse(localStorage.getItem("language"));

export let currentLanguage = lang?.language?.language_name||"";
export let shortLanguage = lang?.language?.language_code||"";

export const messages = [
  //======================player message ======================
  //===========================================================
  {
    player_activate:
      "This will Activate / Deactivate the Players. Only Activated Players can log in to their Players Zone.",
  },
  {
    player_featured:
      'Activating this will display the Players on the Live Website in the "Players" section.',
  },
  {
    player_member:
      'Activating this feature will define the Players as a "Member" of your Academy.',
  },

  //======================coach message ======================
  //==========================================================
  {
    coaches_activate:
      "This will Activate / Deactivate the Coaches. Only Activated Coaches can log in to their Coaches Zone.",
  },
  {
    coaches_featured:
      'Activating this will display the Coaches on the Live Website in the "Coaches" section.',
  },
  {
    coaches_member:
      'Activating this feature will define the Coaches as a "Member" of your Academy.',
  },

  //======================member message ======================
  //===========================================================
  {
    members_activate:
      "This will Activate / Deactivate the Members. Only Activated Members can log in to their Members Zone.",
  },
  {
    members_featured:
      'Activating this will display the Members on the Live Website in the "Members" section.',
  },
];

export const tipsdata = [
  {
    title: "Sportama Tennis",
    description:
      "A team of dedicated Coaches & Trainers, consistently working for the betterment of tennis in Indonesia. We are located at The Sultan Hotel and Klub Kelapa Gading.",
  },
  {
    title: "Sportama Mission",
    description:
      "To teach each of our athletes how to maximize their potential as an athlete and be responsible citizen of the society.",
  },
  {
    title: "Our Programs",
    description:
      "Our Coaches deliver programs for Under 10s, Beginner, Intermediate, Advanced, and High-Performance tennis players. We also have training modules for Club / Leisure players. ",
  },
];

import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import RouteFile from "./RouteFolder/RouteFile";
import { useDispatch, useSelector } from "react-redux";
import { themeColor1, themeColor2, wc_hex_is_light } from "./components/utils";
import { themeState } from "./redux/themeColorSlice";
import { fetchCustomizationData } from "./redux/frontendSlice";
import { useNavigate } from "react-router-dom";
import { useIdle } from "@uidotdev/usehooks";
import LogoutModal from "./components/LogoutModal";
import { ToastContainer } from "react-toastify";
import { logoutUser } from "./redux/logoutSlice";
import { clearState } from "./redux/loginSlice";
import { notify } from "./components/Notification";
import { ScrollProvider } from "./ScrollContext";
import "./styles/globals.css";
import "./public/scss/main.scss";
import "react-quill/dist/quill.snow.css";
import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import RequestForm from "./Pages/RequestForm";

function App() {
  const dispatch = useDispatch();
  const [fetchError, setFetchError] = useState();
  const queryString = window.location.search.replace("?zoom=", "");
  const colorArr = [];
  const loginState = useSelector(state => state.login);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const frontend = useSelector(state => state?.frontend?.customizationData);

  const idleTime = loginState.user_type === "academy" ? 45 * 60 * 1000 : 15 * 60 * 1000; // 45 minutes for academy, 15 minutes for others
  const isIdle = useIdle(idleTime);

  const currentAppVersion = localStorage.getItem("ewpVer") || null;
  const academyId = localStorage.getItem("academy") || null;

  useEffect(() => {
    if (frontend) {
      if (!Object.keys(frontend)?.length > 0) {
        dispatch(fetchCustomizationData());
        // console.log("I don't have any data in local storage");
      } else if (frontend?.customize_frontend?.version >= 0) {
        if (frontend?.customize_frontend?.version !== parseInt(currentAppVersion)) {
          dispatch(fetchCustomizationData());
          // console.log("My App Version does not match");
        }
      } else if (frontend.id !== academyId) {
        dispatch(fetchCustomizationData());
      }
      // else {
      //   console.log("I already have data!");
      // }
    }
  }, []);

  useEffect(() => {
    colorArr.push(frontend?.customize_frontend?.first_color);
    colorArr.push(frontend?.customize_frontend?.second_color);
    localStorage.setItem("userinfo", `"${loginState?.access_token}"`);
    dispatch(themeState({ colors: colorArr }));
    localStorage.setItem("theme", colorArr);
    // TODO: country.id / code does not exist, currency & make separate api for metas
    localStorage.setItem("countryId", frontend?.country?.id);
    localStorage.setItem("countryCode", frontend?.country?.shortname.toLowerCase());
    localStorage.setItem("currency", frontend?.currency?.currency_symbol);

    frontend?.academy_languages && localStorage.setItem("language", JSON.stringify(frontend?.academy_languages[0]));
  }, []);

  useEffect(() => {
    if (loginState.user_type !== "") {
      if (isIdle) {
        setShowModal(true);
      }
    }
  }, [isIdle]);

  useEffect(() => {
    const isExpired = localStorage.getItem("tokenExpired") && localStorage.getItem("tokenExpired") === "true";
    if (isExpired) {
      dispatch(logoutUser());
      window.localStorage.clear();
      notify("User has been logged out successfully!", "success");
      if (loginState.user_type === "player") {
        navigate("/player-login");
      } else if (loginState.user_type === "coach") {
        navigate("/coach-login");
      } else if (loginState.user_type === "academy") {
        navigate("/academy-login");
      }
      dispatch(clearState());
    }
  }, [localStorage.getItem("tokenExpired")]);

  return (
    <ScrollProvider>
      {/* <RequestForm/> */}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      {/* <div className="tooltipContainer"></div> */}
      {/* <Loader/> */}

      {showModal && <LogoutModal showModal={showModal} setShowModal={setShowModal} />}
      {fetchError !== true ? (
        <div className="App" style={{ zoom: queryString }}>
          <RouteFile />
          {/* <RemoveBackground/> */}
          {/* <TipsPopup /> */}
          {/* <SocialBar /> */}
        </div>
      ) : (
        <div className="d-flex  align-items-center justify-content-center vh-100 w-100 text-center">
          <div>
            <h1
              className="fw-bold"
              style={{
                color: wc_hex_is_light(themeColor1()) !== true ? themeColor1() : themeColor2(),
              }}>
              We're Sorry!
            </h1>
            <h4>This page is under maintenance</h4>
            <p>We're preparing to serve you better.</p>
          </div>
        </div>
      )}

      <style jsx="true">{`
        // .rbc-btn-group button.btn {
        //   background: ${themeColor2()} !important;
        //   color: ${wc_hex_is_light(themeColor2()) === false ? "#fff" : "#000"} !important;
        // }
        .draggableForm form,
        .modal-header {
          border-top: 3px solid ${themeColor2()} !important;
        }
        .modal-title:after {
          background: ${themeColor2()};
        }
        .selected_template {
          background: ${themeColor2()}1c;
        }
      `}</style>
    </ScrollProvider>
  );
}

export default App;
